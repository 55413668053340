import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Check2Circle, XCircle } from 'react-bootstrap-icons';

import Framework from '../components/framework';
import Link from '../components/framework/link';
import NavTop from '../components/framework/nav-top';
import Seo from '../components/framework/seo';
import Suspense from '../components/framework/suspense';
import useRequest from '../hooks/use_request';
import useTranslation from '../hooks/use_translation';

const Activate = () => {
	const location = useLocation();
	const search = queryString.parse(location?.search);
	const { t } = useTranslation('verify');

	return (
		<Framework>
			<Seo title={t('title')} />
			<NavTop></NavTop>

			<Container className="mt-5 pt-5 o-hidden signin">
				<Row className="justify-content-center">
					<Col lg="4">
						<div className="my-5">
							<h1 className="h3 text-center pb-2">{t('t1')}</h1>
							<div
								className={`p-lg-5 p-3 my-4 rounded-4 bg-white signin-container text-center`}
							>
								<Suspense tags={{ component: 'Activate' }}>
									<Request sid={search?.sid} />
								</Suspense>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Framework>
	);
};

const Request = ({ sid }) => {
	const { data } = useRequest(sid ? 'user_username.verify' : null, {
		body: { sid },
		revalidateIfStale: false,
		revalidateOnReconnect: false,
	});
	const { t } = useTranslation('verify');

	return (
		<>
			{!sid && <p>{t('t2')}</p>}
			{sid && data.status == 'error' && (
				<div>
					<XCircle className="display-4 mb-4 text-danger" />
					<p>{t('t3')}</p>
				</div>
			)}
			{sid && data.status == 'ok' && (
				<div>
					<Check2Circle className="display-4 mb-4 text-success" />
					<p>{t('t4')}</p>
				</div>
			)}

			<Link className="btn btn-link lead" to="/">
				{t('t5')}
			</Link>
		</>
	);
};

Request.propTypes = {
	sid: PropTypes.string,
};

export default Activate;
